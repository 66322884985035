<template>
  <div></div>
</template>

<script>
import { cancelTransaction } from '@/services/braintree.service'
import { notification } from 'ant-design-vue'
export default {
  name: 'CancelTransaction',
  created() {
    this.init()
  },
  methods: {
    async init() {
      try {
        const data = await cancelTransaction()
        notification.error({
          message: data.message || 'Something went wrong',
        })
        this.$route.push('/home')
      } catch (err) {
        // notification.error({
        //   message: err.message || 'Something went wrong',
        // })
      }
    },
  },
}
</script>

<style></style>
